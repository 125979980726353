@import './../../../../scss/theme-bootstrap';

.basic-carousel-formatter {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &--large {
    max-width: $max-width-large;
  }
  &--medium {
    max-width: $max-width;
  }
  &--small {
    max-width: $max-width-small-formatters;
  }
  &--full {
    max-width: 100%;
  }
  &--padding {
    .basic-carousel .slick-slide.basic-carousel__slide {
      padding: 0 7px;
    }
  }
  &.arrows-hover {
    > .slick-prev,
    > .slick-next {
      @include breakpoint($medium-up) {
        visibility: hidden;
      }
    }
    &:hover {
      > .slick-prev,
      > .slick-next {
        @include breakpoint($medium-up) {
          visibility: visible;
        }
      }
    }
  }
  &.align-left {
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: left;
    }
  }
  &.align-right {
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: right;
    }
  }
  .slick-slide {
    padding: 0;
    &.basic-carousel__slide {
      padding: 0;
    }
  }
  &--mob_peeking {
    .slick-list {
      @include breakpoint($medium-down) {
        padding: 10px 10%;
      }
    }
    .slick-track > .slick-slide:not(.slide-active) {
      @include breakpoint($medium-down) {
        opacity: 0.5;
      }
    }
  }
  &--pc_peeking {
    .slick-list {
      @include breakpoint($medium-up) {
        padding: 10px 10%;
      }
    }
    .slick-track > .slick-slide:not(.slide-active) {
      @include breakpoint($medium-up) {
        opacity: 0.5;
      }
    }
  }
}

.basic-carousel {
  font-size: 16px;
  max-width: 100%; // we need this due to the flex parent
  &.carousel--small-arrows {
  }
  &.carousel--small-arrows--pc {
    @include breakpoint($medium-up) {
    }
  }
  &.carousel--trans-arrows {
    > .slick-arrow {
      background-color: $color-carousel-arrow-bg;
      top: 25%;
      @include breakpoint($medium-up) {
        top: 35%;
      }
    }
  }
  &.carousel--trans-arrows--pc {
    @include breakpoint($medium-up) {
      > .slick-arrow {
        background-color: $color-carousel-arrow-bg;
      }
    }
  }
  &.carousel--default-arrows {
    > .slick-arrow {
      background-color: $color-carousel-arrow-bg-solid;
    }
  }
  &.carousel--default-arrows--pc {
    @include breakpoint($medium-up) {
      > .slick-arrow {
        background-color: $color-carousel-arrow-bg-solid;
      }
    }
  }
  &.carousel--white-arrows {
    > .slick-arrow {
      background-color: $color-overlay-75;
    }
  }
  &.carousel--white-arrows--pc {
    @include breakpoint($medium-up) {
      > .slick-arrow {
        background-color: $color-overlay-75;
      }
    }
  }
}

@mixin carousel-show-first-slide {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  > .basic-carousel__slide {
    flex-shrink: 0;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }
}

// FOUC
.basic-carousel-formatter:not(.basic-carousel-formatter--pc_peeking) {
  .basic-carousel:not(.slick-initialized) {
    @include breakpoint($medium-up) {
      &[data-slides-show='1'] {
        @include carousel-show-first-slide;
      }
    }
    @include breakpoint($medium-down) {
      &[data-slides-show-mobile='1'] {
        @include carousel-show-first-slide;
      }
    }
  }
}
